import React from 'react';

function AnalyticsPage(props) {
    return (
        <div>
            AnalyticsPage
        </div>
    );
}

export default AnalyticsPage;
import React from 'react';
import { MenuHamburger } from '../../styles/decal.styles';
import { useSelector, useDispatch } from 'react-redux';

import { toggleHamburgerMenu } from '../../redux/modalHandler.reducer';

function OpenMenuHamburgerButton(props) {

    //////////STATE MANAGEMENT\\\\\\\\\\
    const hamburgerMenuStatus = useSelector((state) => state.handleModal.isHamburgerMenuOpen)
    const dispatch = useDispatch()

    const openDrawerMenu = () => {
        dispatch(toggleHamburgerMenu());
        document.body.style.overflow = 'hidden';
    }

    return (
        <div style={{marginRight: '30px'}}>

            <MenuHamburger onClick={() => openDrawerMenu()}/>

        </div>
    );
}

export default OpenMenuHamburgerButton;
//general imports
import styled from "styled-components";

export const LargeHeaderText = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 65px;
    line-height: 75px;

    background: linear-gradient(92.32deg, #F1B314 0%, #DA21B1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
`;

export const BasicText_White_Small = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;

    color: #FFFFFF;

    @media (max-width: 400px) {
        font-size: 10px;
    }
`;

export const Footer_Copywrite_Text = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    display: flex;
    align-items: center;

    color: #C4C4C4;
`;

export const BasicText_White_Small_Linked = styled.span`
    font-weight: 900;
    text-decoration-line: underline;

    &:hover{
        cursor: pointer;
    }
`;

export const SmallPricingDetailText = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: #FFFFFF;
`;

export const HeaderLinkText = styled.a`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-decoration: none;

    height: 35px;
    margin-bottom: calc(-35px/2);

    color: rgba(29, 29, 29, 0.8);
    transition: 0.15s linear;

    &:hover{
        cursor: pointer;
        transition: 0.15s linear;
        color: #DE379B;
    }

    @media (max-width: 600px) {
        visibility: hidden;
        width: 0px;
        height: 0px;
    }
`;

export const BasicText_Grey_Small_Bold = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 11px;

    color: rgba(29, 29, 29, 0.6);
`;

export const Medium_Montser_Black = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;

    color: #000000;
`;

export const ParagraphText = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;

    color: #000000;
`;

export const Kuumba_Text_Accent = styled.span`
    font-weight: 700;

    background: linear-gradient(88.93deg, #F1B314 46.41%, #DA21B1 82.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
`;

//CMS Specific Text

export const CMS_Large_Body_Text = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 55px;

    color: #DE379B;

    @media (max-width: 800px) {
        font-size: 37.5px;
        line-height: auto;
    }
`;

export const CMS_Paragraph_Body_Text = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;

    text-align: center;

    color: #666666;

    @media (max-width: 800px) {
        font-size: 20px;
        line-height: auto;
    }
`;

export const CMS_Small_Body_Text = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    color: #DE379B;
`;

export const CMS_Grey_Paragraph_Body_Text = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;

    color: #C4C4C4;
`;

export const PinkTextHighlight = styled.span`
    color: #DE379B;
`;

export const FAQsText = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 26px;

    color: #B3B3B3;
`;

export const PlatformNumberTextDecal = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 200px;
    line-height: 244px;

    text-align: center;

    color: #F6F6F6;
`;

export const PricingHeaderText = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 45px;
    line-height: 55px;
    text-align: center;

    color: #FFFFFF;
`;

export const PricingParagraphText = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;

    color: #969696;
`;

export const PricingSubHeaderText = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;

    color: #FFFFFF;
`;

export const PricingSubContentText = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;

    color: #969696;
`;

export const PriceText = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 61px;

    color: #FFFFFF;
`;

//Authentication Typography
export const AuthFormHeader = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #1D1D1D;
`;

export const CreateNewAccountCTA = styled.div`
    font-weight: 700;
    text-decoration-line: underline;
    min-width: 100%;
    color: #DE379B
    ;

    &:hover {

        cursor: pointer;

    }
`;

export const AuthInputRadioButtonText = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #1D1D1D;
`;

export const AuthInputRadioButtonTextAccent = styled.span`
    text-decoration-line: underline;
    color: #DE379B;
    font-weight: 700;

    &:hover {
        cursor: pointer;
    }
`;

export const AuthSocialDivider = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;

    color: #1D1D1D;
`;

export const AuthenticatorAppButtonText = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;

    text-align: right;

    color: #DE379B;
`;

export const ToSandPrivacyText = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

    color: #DE379B;

    &:hover {
        cursor: pointer;
    }
`;

///APP TYPOGRAPHY\\\
export const CarbonBlackAppText = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    color: #000000;
`;

export const CarbonBlackAppSubHeaderText = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;


    color: #101010;
`;
import React from 'react';

function DigitalEngineeringPage(props) {
    return (
        <div>
            Digital Engineering Solutions
        </div>
    );
}

export default DigitalEngineeringPage;
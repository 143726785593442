import { configureStore } from '@reduxjs/toolkit';
import toggleAuthReducer from '../toggleLoginSignup';
import closeAccounementBarReducer from '../closeAccounementBar.reducer';
import modalHandlerReducer from '../modalHandler.reducer';
import authSlice from '../auth.slice';

export const store = configureStore({
  reducer: {
      toggleAuth: toggleAuthReducer,
      closeAnnouncementBar: closeAccounementBarReducer,
      handleModal: modalHandlerReducer,
      authentication: authSlice,
  },
})
import React from 'react';

//decal imports
import { Language_Globe, WedgePointer } from '../../styles/decal.styles';

//typography imports
import { BasicText_Grey_Small_Bold } from '../../styles/typography.styles';


function LanguageSwitchButton(props) {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            flexFlow: 'row',
            gap: '5px', opacity: '0.65',
            cursor: 'pointer',
        }}>
            <Language_Globe />
            <BasicText_Grey_Small_Bold>en</BasicText_Grey_Small_Bold>
            <WedgePointer />
        </div>
    );
}

export default LanguageSwitchButton;
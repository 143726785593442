//general imports
import styled, { keyframes } from "styled-components";

//Images
import VideoOverlay from '../assets/VideoOverlay.png';

//Key Frames
const SlideInRighttoLeft = keyframes`

    0% {
        opacity: 0;
        transform: translateX(100px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
`;

const SlideInToptoBottom = keyframes`

    0% {
        opacity: 0;
        transform: scale(0.95) translateY(-50px);
    }

    100% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
`;

const RightDrawerSlideOut = keyframes`
    0% {
        transform: translateX(320px);
    }

    100% {
        transform: translateX(0);
    }
`;

//Modals
export const HeaderVideoModal = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 445px;
    height: 285px;

    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    margin-top: 15px;

    animation: ${SlideInRighttoLeft} 0.25s ease-in-out;
`;

export const MenuDrawer = styled.div`
    display: flex;
    flex-flow: column;

    padding: 25px;

    height: 100vh;
    width: 275px;
    max-width: 100%;

    background: white;
    position: absolute;
    top: 0;
    right: 0;

    @media (max-width: 420px) {
        min-width: calc(100% - 50px);
    }

    animation: ${RightDrawerSlideOut} 0.20s linear;

`;

export const MenuDrawerContent = styled.div`
    display: flex;
    flex-flow: column;
    height: calc(100% - 50px);
    justify-content: space-between;
`;

export const WaitlistModalContainer = styled.div`
    display: flex;
    flex-flow: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    background: white;
    width: 60%;
    max-width: 700px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 5%;
    padding-bottom: 5%;

    animation: ${SlideInToptoBottom} 0.25s linear;
`;

//Overlays
export const BlurryModalOverlay = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(3.5px);
`;

//Boxes
export const AnnouncementBarContainer = styled.div`
    display: flex;
    width: calc(100% - 20px);
    justify-content: space-between;
    align-items: center;
    background: #F6F6F6;
    height: ${props => props.isOpen ? '35px' : '0px'};
    transition: 0.15s linear;
    visibility: ${props => props.isOpen ? 'visible' : 'collapse'};
    position: fixed;
    top: 0;
    padding-left: 20px;
    padding-right: 20px;

    &:hover{
        transition: 0.15s linear;
        cursor: pointer;
    }
`;

export const NotificationCloseoutBTNContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    background: rgba(0, 0, 0, 0.05);
    transition: 0.15s linear;
    
    &:hover{
        transition: 0.15s linear;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.15);
    }
`;

export const CMSNavigationHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    height: 75px;
    position: fixed;
    width: 100%;
    transition: 0.15s linear;
    top: ${props => props.isOpen ? '35px' : '0px'};
    box-shadow: ${props => props.scrolled ? '0px 5px 15px rgba(0, 0, 0, 0.05)' : ''};
`;

export const PageBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.15s linear;
    margin-top: ${props => props.isOpen ? '110px' : '75px'};
    background: white;
`;

export const SectionContainer = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-top: 35px;
    margin-bottom: 35px;
    background: white;
`;

export const VideoBoxContainer = styled.a`
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: black;
    border-radius: 7px;
    background-image: url(${VideoOverlay});
    background-repeat: no-repeat;
    background-size: cover;
`;

export const HeroImgContainer = styled.div`
    flex-grow: 1;

    margin-left: -32%;
    margin-right: -32%;

    @media (max-width: 2000px) {
        margin-left: -42%;
        margin-right: -10%;
    }

    @media (max-width: 1600px) {
        margin-left: -32%;
        margin-right: -7%;
    }

    @media (max-width: 1300px) {
        margin-left:0px;
        margin-right: 0px;
    }

    animation: ${SlideInRighttoLeft} 1.2s ease-in-out;
`;

export const CheckMarkContainer = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;

    @media (max-width: 500px) {
        align-items: flex-start;
    }
`;

export const FilledTextAccent = styled.span`
    align-items: center;
    text-align: center;

    background: linear-gradient(94.32deg, #F1B314 0%, #DA21B1 100%);
    border-radius: 2px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;

    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;

    margin-left: 7px;

    color: #FFFFFF;

    transition: 0.15s linear;

    &:hover{
        transition: 0.15s linear;
        cursor: pointer;
        filter: brightness(90%);
    }
`;

export const SolutionsGridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(250px, 1fr));
    align-items: center;
    justify-content: space-between;
    gap: 71px;

    @media (max-width: 1440px) {
        row-gap: 181px
    }
`;

export const PlatformGridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(240px, 1fr));
    align-items: center;
    justify-content: space-between;
    gap: 61px; 
`;

export const FAQsGridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(450px, 1fr));
    align-items: center;
    justify-content: space-between;
    gap: 65px; 
    margin-top: 65px;

    @media (max-width: 500px) {
        grid-template-columns: repeat(auto-fill,minmax(175px, 1fr));
    }
`;

export const PricingGridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(400px, 1fr));
    align-items: center;
    justify-content: space-between;
    gap: 85px; 
    margin-top: 65px;

    @media (max-width: 500px) {
        grid-template-columns: repeat(auto-fill,minmax(175px, 1fr));
    }
`;

export const PriciingTableWhite = styled.div`
    display: flex;
    align-self: end;
    min-height: 100px;
    border-radius: 10px;
    border: 2px solid;

    background: rgba(217, 217, 217, 0.0001);
    
    border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0) 4.17%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 98.44%);
    //border-image-source: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 98.44%);
    border-image-slice: 1;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 35px;
    padding-right: 35px;
`;

export const PriciingTablePink = styled.div`
    display: flex;
    min-height: 100px;
    border-radius: 10px;
    border: 2px solid;

    background: rgba(217, 217, 217, 0.0001);
    border-image-source: linear-gradient(180deg, #DE379B 0%, rgba(222, 55, 155, 0) 98.44%);

    border-image-slice: 1;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 35px;
    padding-right: 35px;
`;

export const PricingBestForSmallTeams = styled.div`
    display: flex;
    justify-content: center;
    height: 45px;
    width: 100%;
    background: #DE379B;
    border-radius: 10px 10px 0px 0px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
`;

export const AuthContainer = styled.div`
    margin-top: 35px;
    margin-left: -195px;

    @media (max-width: 1500px) {
        margin-left: -395px;
    }

    @media (max-width: 1450px) {
        margin-left: -445px;
    }

    @media (max-width: 950px) {
        margin-left: 0;
        width: 100%;
        align-items: stretch;

    }

    animation: ${SlideInRighttoLeft} 1s ease-in-out;
`;

export const AuthFormContainer = styled.div`
    display: flex;
    flex-flow: column;
    min-width: 340px;
    max-width: 340px;

    align-self: flex-start;
    align-items: center;

    background: #FFFFFF;
    box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    padding: 35px;
    gap: 23px;

    @media (max-width: 500px) {
        min-width: 175px;
        max-width: 100%;
    }
`;

export const AuthFormSwitcherContainer = styled.div`
    display: flex;
    flex-flow: column;
    gap: 18px;

    justify-content: center;
    align-items: center;

    background: #F6F6F6;
    height: 55px;
    width: calc(100% + 70px);

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;

    color: #1D1D1D;
`;

//Buttons
export const UnfilledButton = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    padding: 10px;
    color: #DE379B;
    border-radius: 2px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    transition: 0.15s linear;
    border: 1px solid #DE379B;
    text-decoration: none;

    &:hover{
        transition: 0.15s linear;
        cursor: pointer;
    }
`;

export const FilledButton = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #DE379B;
    padding: 10px;
    color: #FFFFFF;
    border-radius: 2px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    transition: 0.15s linear;
    text-decoration: none;

    border: 1px solid #DE379B;

    &:hover{
        transition: 0.15s linear;
        cursor: pointer;
        filter: brightness(90%);
    }
`;

export const DrawerMenuLinks = styled.div`
    display: flex;
    background: white;
    width: calc(100% - 15px);
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 2px;
    margin-top: 15px;
    transition: 0.15s linear;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

    &:hover{
        transition: 0.15s linear;
        cursor: pointer;
        color: #DE379B;
        padding-left: 15px;
        filter: brightness(99%);
    }
`;

export const PricingButtonWhite = styled.a`
    height: 46px;
    width: 100%;
    background: rgba(217, 217, 217, 0.0001);
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    margin-top: 5px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration: none;

    color: #FFFFFF;

    transition: 0.15s linear;

    &:hover{
        transition: 0.15s linear;
        cursor: pointer;
        color: #DE379B;
        background: white;
    }
`;

export const PricingButtonPink = styled.a`
    height: 46px;
    width: 100%;
    background: #DE379B;
    border: 1px solid #DE379B;
    border-radius: 5px;
    margin-top: 5px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration: none;

    color: #FFFFFF;

    transition: 0.25s linear;

    &:hover{
        transition: 0.25s linear;
        cursor: pointer;
        color: #1D1D1D;
    }
`;

export const SocialAuthButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    background: white;

    border: 0.5px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 3px;

    height: 55px;
    width: 100%;

    transition: 0.15s linear;

    &:hover {
        cursor: pointer;
        border: 1px solid #DE379B;
        box-sizing: border-box;
        border-radius: 3px;
        transition: 0.15s linear;
    }
`;
import React from 'react';

function KBasePage(props) {
    return (
        <div>
            KBasePage
        </div>
    );
}

export default KBasePage;
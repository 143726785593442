import React from 'react';

//State Imports
import { useSelector, useDispatch } from 'react-redux';
import { toggleWaitlist } from '../../redux/modalHandler.reducer';

//Styles
import { FilledFormSubmitButton, GeneralFormInput, SingleLineForm } from '../../styles/form.styles';
import { FilledButton } from '../../styles/container.styles';

function SingleBarEmailForm(props) {

    //////////STATE MANAGEMENT\\\\\\\\\\
    const waitlistModalStatus = useSelector((state) => state.handleModal.isWaitlistOpen)
    const dispatch = useDispatch()

    const handleEmailFormSubmit = () => {
        console.log("you did it!");
        dispatch(toggleWaitlist());
        document.body.style.overflowY = '';
    }

    const submitEmailForm = () => {
        console.log("you did it!");
        document.body.style.overflowY = '';
    }

    return (
        <SingleLineForm onSubmit={() => waitlistModalStatus? handleEmailFormSubmit() : submitEmailForm()}>
            <GeneralFormInput placeholder='Enter your email'/>
            <FilledFormSubmitButton type={"submit"} value={"Join Waitlist"} style={{minWidth: '130px', fontSize: '14px', fontWeight: '700'}} />
        </SingleLineForm>
    );
}

export default SingleBarEmailForm;
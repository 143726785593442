import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({

    name: "authInitializer",
    initialState: {
        userInfo: null,
    },

    reducers: {

        setAuth: (state, { payload }) => {
            if (!payload) return;
            state.userInfo = payload;
        },

    },

});

export const { setAuth } = authSlice.actions;

export default authSlice.reducer;
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isNew: true,
}

export const toggleLoginSignup = createSlice({
    name: "toggleAuth",
    initialState,
    reducers: {
        toggle: (state) => ({
            isNew: !state.isNew
        }),
    }
});

export const { toggle } = toggleLoginSignup.actions

export default toggleLoginSignup.reducer
//general imports
import styled from "styled-components";

//import images
import White_X from '../assets/White_X.svg';
import Grey_X from '../assets/Grey_X.svg';
import KuumbaLogo from '../assets/KUUMBA.svg';
import PurpleKuumbaLogo from '../assets/KUUMBA_Purple.svg';
import UDEKuumbaLogo from '../assets/UDE_Kuumba_Logo.svg';
import UDEFooterLogo from '../assets/UDE_Footer_Logo.svg';
import Wedge from '../assets/Wedge.svg';
import Globe from '../assets/Globe.svg';
import Play from '../assets/PlayDecal.svg';
import Hamburger from '../assets/MenuHamburger.svg';
import CheckMark from '../assets/CheckMark.svg';
import NASA from '../assets/NASALogo3.png';
import NSF from '../assets/NSFLogo3.png';
import DLA from '../assets/DLALogo3.png';
import TwitterIcon from '../assets/Twitter.svg';
import DribbleIcon from '../assets/Dribble.svg';
import LinkedInIcon from '../assets/LinkedIn.svg';
import YouTubeIcon from '../assets/YouTube.svg';
import Dots from '../assets/Dots.svg';
import TextFlare from '../assets/Text_Flare.svg';
import PricingSwizzle from '../assets/PricingSwizzle.png';
import Indie from '../assets/Indie.png';
import Team from '../assets/Team.png';
import Enterprise from '../assets/Enterprise.png';
import Faces from '../assets/SchedulerFaces.png';
import PricingCheckMark from '../assets/PricingCheckMark.png';
import LeftSocialDivider from '../assets/leftDivi.png';
import RightSocialDivider from '../assets/rightDivi.png';
import GoogleAuthIcon from '../assets/Google.png';
import GitHubAuthIcon from '../assets/GitHub.png';
import MicrosoftAuthIcon from '../assets/Microsoft.png';
import QRCAuthIcon from '../assets/QRC.png';

export const Social_Icon_Twitter_Decal = styled.div`
    background-image: url(${TwitterIcon});
    background-repeat: no-repeat;
    width: 25px;
    height: 20px;

    &:hover{
        cursor: pointer;
    }
`;

export const Social_Icon_YouTube_Decal = styled.div`
    background-image: url(${YouTubeIcon});
    background-repeat: no-repeat;
    width: 30px;
    height: 20px;

    &:hover{
        cursor: pointer;
    }
`;

export const Social_Icon_Dribble_Decal = styled.div`
    background-image: url(${DribbleIcon});
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;

    &:hover{
        cursor: pointer;
    }
`;

export const Social_Icon_LinkedIn_Decal = styled.div`
    background-image: url(${LinkedInIcon});
    background-repeat: no-repeat;
    width: 21px;
    height: 20px;

    &:hover{
        cursor: pointer;
    }
`;

export const White_X_Decal = styled.div`
    background-image: url(${White_X});
    background-repeat: no-repeat;
    width: 14px;
    height: 14px;
    transform: rotate(90deg);
`;

export const GoogleAuthDecal = styled.div`
    background-image: url(${GoogleAuthIcon});
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
`;

export const GitHubAuthDecal = styled.div`
    background-image: url(${GitHubAuthIcon});
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
`;

export const MicrosoftAuthDecal = styled.div`
    background-image: url(${MicrosoftAuthIcon});
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
`;

export const QRCAuthDecal = styled.div`
    background-image: url(${QRCAuthIcon});
    background-repeat: no-repeat;
    width: 17px;
    height: 17px;
`;

export const LeftSocialDividerDecal = styled.div`
    background-image: url(${LeftSocialDivider});
    background-repeat: no-repeat;
    width: 55px;
    height: 2px;
`;

export const RightSocialDividerDecal = styled.div`
    background-image: url(${RightSocialDivider});
    background-repeat: no-repeat;
    width: 55px;
    height: 2px;
`;

export const Grey_X_Decal = styled.div`
    background-image: url(${Grey_X});
    background-repeat: no-repeat;
    width: 35px;
    height: 35px;
    transition: 0s linear;

    &:hover{
        transition: 0s linear;
        cursor: pointer;
        filter: brightness(85%);
    }
`;

export const Kuumba_Logo_Decal = styled.div`
    background-image: url(${KuumbaLogo});
    background-repeat: no-repeat;
    width: 115.9px;
    height: 18px;

    &:hover{
        cursor: pointer;
    }
`;

export const UDE_Footer_Logo_Decal = styled.div`
    background-image: url(${UDEFooterLogo});
    background-repeat: no-repeat;
    width: 55px;
    height: 20px;

    &:hover{
        cursor: pointer;
    }
`;

export const Purple_Kuumba_Logo_Decal = styled.div`
    background-image: url(${PurpleKuumbaLogo});
    background-repeat: no-repeat;
    width: 115.9px;
    height: 18px;

    &:hover{
        cursor: pointer;
    }
`;

export const UDE_Kuumba_Logo_Decal = styled.div`
    background-image: url(${UDEKuumbaLogo});
    background-repeat: no-repeat;
    width: 212px;
    height: 26px;

    &:hover{
        cursor: pointer;
    }
`;

export const Language_Globe = styled.div`
    background-image: url(${Globe});
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;

        &:hover{
            cursor: pointer;
        }
`;

export const WedgePointer = styled.div`
    background-image: url(${Wedge});
    background-repeat: no-repeat;
    width: 5px;
    height: 3px;

        &:hover{
            cursor: pointer;
        }
`;

export const VideoPlay = styled.div`
    background-image: url(${Play});
    background-repeat: no-repeat;
    width: 75px;
    height: 75px;
    transition: 0.15s linear;
    opacity: 0.75;

        &:hover{
            cursor: pointer;
            transition: 0.15s linear;
            opacity: 1;
        }
`;

export const MenuHamburger = styled.div`
    background-image: url(${Hamburger});
    background-repeat: no-repeat;
    width: 23px;
    height: 16px;
    transition: 0.15s linear;

        &:hover{
            cursor: pointer;
            transition: 0.15s linear;
            filter: brightness(85%);
        }
`;

export const CheckMarkDecal = styled.div`
    background-image: url(${CheckMark});
    background-repeat: no-repeat;
    width: 23px;
    height: 19.1px;

    @media (max-width: 500px) {
        margin-top: 6px;
    }
`;

export const NASALogoDecals = styled.div`
    background-image: url(${NASA});
    background-repeat: no-repeat;
    width: 79.69px;
    height: 22.29px; 
`;

export const NSFLogoDecals = styled.div`
    background-image: url(${NSF});
    background-repeat: no-repeat;
    width: 55.15px;
    height: 56px; 
`;

export const DLALogoDecals = styled.div`
    background-image: url(${DLA});
    background-repeat: no-repeat;
    width: 43.46px;
    height: 52.64px; 
`;

export const DotDecals = styled.div`
    background-image: url(${Dots});
    background-repeat: no-repeat;
    width: 300px;
    height: 332px; 
`;

export const TextFlareDecal = styled.div`
    background-image: url(${TextFlare});
    background-repeat: no-repeat;
    width: 60px;
    height: 55px; 
`;

export const Swizzle = styled.div`
    background-image: url(${PricingSwizzle});
    background-repeat: no-repeat;
    width: 155px;
    height: 30px; 
`;

export const IndieDecal = styled.div`
    background-image: url(${Indie});
    background-repeat: no-repeat;
    width: 30px; 
    height: 35px;
`;

export const TeamDecal = styled.div`
    background-image: url(${Team});
    background-repeat: no-repeat;
    width: 43px; 
    height: 35px;
`;

export const EnterpriseDecal = styled.div`
    background-image: url(${Enterprise});
    background-repeat: no-repeat;
    width: 40px; 
    height: 35px;
`;

export const ScheduleFacesDecal = styled.div`
    background-image: url(${Faces});
    background-repeat: no-repeat;
    width: 42px; 
    height: 24px;
`;

export const PricingCheckMarkDecal = styled.div`
    background-image: url(${PricingCheckMark});
    background-repeat: no-repeat;
    width: 23px; 
    height: 17px;
`;
import React from 'react';
import { HeaderVideoModal, VideoBoxContainer } from '../../styles/container.styles';
import { VideoPlay } from '../../styles/decal.styles';

//Style Imports

function HowItWorksVideoModal(props) {
    return (
        <HeaderVideoModal>
            <VideoBoxContainer href={"https://www.youtube.com/channel/UCKt_qdcHgCq7VjEDY6V2dmA"} target="_blank">
                <VideoPlay />
            </VideoBoxContainer>
        </HeaderVideoModal>
    );
}

export default HowItWorksVideoModal;
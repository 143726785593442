//Testing Imports
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    GoogleAuthProvider, 
    signInWithPopup, 
    GithubAuthProvider,
    OAuthProvider,
    signOut,onAuthStateChanged,
    sendPasswordResetEmail, updateProfile,
} from 'firebase/auth';
import { useEffect } from 'react';

import { auth } from './firebase-config';


//Initiate Social Login
const googleProvider = new GoogleAuthProvider();
const gitHubProvider = new GithubAuthProvider();
const microsoftProvider = new OAuthProvider();









//Testing how tf this works
export const testingPushingData = (data) => {
    console.log("has account", data.fullName);
};

export const testingPushingData2 = (data) => {
    console.log("create account", data.fullName);
};


////////////////////////////////////////////////
////////Create Authentication Functions/////////
////////////////////////////////////////////////

/////Email Auth/////

//Create with Email & Password
export const emailPasswordSignUp = async (email, password, fullName) => {

    try {

        await createUserWithEmailAndPassword(auth, email, password)

        updateFirebaseUserProfile(fullName);


    } catch (error) {

        const errorCode = error.code;
        const errorMessage = error.message;

        console.log("there was a problem" + errorCode + errorMessage);
        
    }

}

//Create with Email & Password
export const emailPasswordSignIn = async (email, password) => {

    try {

        await signInWithEmailAndPassword(auth, email, password)


    } catch (error) {

        const errorCode = error.code;
        const errorMessage = error.message;

        console.log("there was a problem" + errorCode + errorMessage);
        
    }

}

/////User Management\\\\\

//Update User Profile
const updateFirebaseUserProfile = async (firebaseName) => {

    try {
        await updateProfile(auth.currentUser, {
            displayName: firebaseName,
        });
        console.log("your display name is: " + auth.currentUser.displayName);
    } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("there was a problem with creating your Display Name" + errorCode + errorMessage);
    }

}


/////Social Auth/////

//Google User Signup
export const googleSignUp = () => {

    signInWithPopup(auth, googleProvider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;

            //dispatch(getUserInfo({userInfo: user}));

            // ...
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });

}


//GitHub
export const gitHubSignUp = () => {

    signInWithPopup(auth, gitHubProvider)
        .then((result) => {
            // This gives you a GitHub Access Token. You can use it to access the GitHub API.
            const credential = GithubAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;

            // The signed-in user info.
            const user = result.user;
            
            // ...
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GithubAuthProvider.credentialFromError(error);
            // ...
        });

}


//Microsoft
export const microsoftSignUp = () => {

    signInWithPopup(auth, microsoftProvider)
        .then((result) => {
            // User is signed in.
            // IdP data available in result.additionalUserInfo.profile.

            // Get the OAuth access token and ID Token
            const credential = OAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;
            const idToken = credential.idToken;
            })
            .catch((error) => {
            // Handle error.
        });

}


//Firebase Logout
export const totalLogOut = () => {

    signOut(auth).then(() => {
        // Sign-out successful.
    }).catch((error) => {
        // An error happened.
    });

}


{/** 

import { 
    getAuth, 
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    GoogleAuthProvider, 
    signInWithPopup, 
    GithubAuthProvider,
    OAuthProvider,
    signOut,onAuthStateChanged,
    sendPasswordResetEmail,
  } from 'firebase/auth';

import app from '../config/firebase-config';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { addUserToFirestore, getLoggedInUser } from '../config/firebase.utils';
import { setAuth, setFirestoreUser } from '../redux/store/auth/auth';

////////////////////////////////////////////////
////////Create Authentication Functions/////////
////////////////////////////////////////////////

//////////CREATE NEW ACCOUNT FUNCTIONS\\\\\\\\\\
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const gitHubProvider = new GithubAuthProvider();
const microsoftProvider = new OAuthProvider();

 //user States
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      dispatch(setAuth(JSON.parse(JSON.stringify(currentUser))));
      if (currentUser) {
        getLoggedInUser(currentUser?.uid).then(res => {
          console.log('loggged user data #############################',res)
          dispatch(setFirestoreUser(res));
        });
      }

    });
    return () => {
      unsubscribe();
    };
}, [auth,dispatch])

//Email & Password
export const emailPasswordSignUp = (email, password) => {

    createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            // ...
            })
            .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ..
        });

}

//Google User Signup
export const googleSignUp = () => {

    signInWithPopup(auth, googleProvider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;

            dispatch(getUserInfo({userInfo: user}));

            // ...
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });

}

//GitHub
export const gitHubSignUp = () => {

    signInWithPopup(auth, gitHubProvider)
        .then((result) => {
            // This gives you a GitHub Access Token. You can use it to access the GitHub API.
            const credential = GithubAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;

            // The signed-in user info.
            const user = result.user;
            
            // ...
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GithubAuthProvider.credentialFromError(error);
            // ...
        });

}

//Microsoft
export const microsoftSignUp = () => {

    signInWithPopup(auth, microsoftProvider)
        .then((result) => {
            // User is signed in.
            // IdP data available in result.additionalUserInfo.profile.

            // Get the OAuth access token and ID Token
            const credential = OAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;
            const idToken = credential.idToken;
            })
            .catch((error) => {
            // Handle error.
        });

}


//Setup Authentication App (This might be firebase phone Auth for 2FA functionallity )


//////////LOGIN TO ACCOUNT FUNCTIONS\\\\\\\\\\

//Email & Password
export const emailPasswordSignIn = async (event) => {

    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)

        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            console.log(user);
            // ...
        })

        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorMessage);
        });

}


//////////LOGOUT OF EXISTING ACCOUNT FUNCTIONS\\\\\\\\\\

//Firebase Logout
export const totalLogOut = () => {

    signOut(auth).then(() => {
        // Sign-out successful.
    }).catch((error) => {
        // An error happened.
    });

}


**/}
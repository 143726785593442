import React, { useState } from 'react';
import { useSelector } from 'react-redux';

//Styles
import { PageBodyContainer } from '../styles/container.styles';
import { CarbonBlackAppSubHeaderText, CarbonBlackAppText, PinkTextHighlight } from '../styles/typography.styles';

function HomePage(props) {

    //////////STATE MANAGEMENT\\\\\\\\\\
    const announcementBarStatus = useSelector((state) => state.closeAnnouncementBar.isAnnouncementBarOpen)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const getWindowWidth = () => {

        setWindowWidth(window.innerWidth)

    }

    window.addEventListener('resize', getWindowWidth)


    return (
        <PageBodyContainer style={{background: 'white'}} isOpen={announcementBarStatus}>
            
            <div style={{width: '100%', display: 'flex', flexFlow: 'column', alignItems: 'center', background: 'white'}}>

                {/************* ADMIN SECTION *************/}
                <div style={{display: 'flex', flexFlow: 'column', background: '#F6F6F6', width: 'calc(100% - 60px)', padding: '30px', gap: '25px'}}>
                    
                    <div id='Admin-Header-Text-Box' style={{display: 'flex', flexFlow: 'column', gap: '10px'}}>

                        <CarbonBlackAppText>
                            Thank you for signing up to 
                            <PinkTextHighlight> Kuumba</PinkTextHighlight>
                            !
                        </CarbonBlackAppText>

                        <CarbonBlackAppSubHeaderText style={{maxWidth: '650px'}}>
                            We are still working around the clock to get this highly anticipated application into your 
                            hands! We are almost done and will keep you updated on our progress.
                        </CarbonBlackAppSubHeaderText>

                    </div>

                    <div id='Admin-Grid'></div>

                </div>


                {/************* PROJECT SECTION *************/}
                <div></div>

            </div>

        </PageBodyContainer>
    );
}

export default HomePage;
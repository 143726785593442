import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

//Page Imports
import LandingPage from './pages/Landing.Page';
import NewLandingPage from './pages/NewLanding.page';
import AnnouncementBarComponent from './components/notifications/AnnouncementBar.component';
import CMSNavigationHeaderComponent from './components/navigation/CMSNavigationHeader.component';
import AnalyticsPage from './pages/Analytics.page';
import HowItWorksPage from './pages/HowItWorks.page';
import KBasePage from './pages/KBase.page';
import KDrivePage from './pages/KDrive.page';
import KonnectPage from './pages/Konnect.page';
import ModelingPage from './pages/Modeling.page';
import RoadmapPage from './pages/Roadmap.page';
import SimulationPage from './pages/Simulation.page';
import PricingPage from './pages/Pricing.page';
import PageNotFoundPage from './pages/PageNotFound.page';
import DigitalEngineeringPage from './pages/DigitalEngineering.page';
import AppNavigationHeaderComponent from './components/navigation/AppNavigationHeader.component';
import { useSelector } from 'react-redux';
import HomePage from './pages/Home.page';

function App() {

  const authStatus = useSelector((state) => state.authentication.userInfo);

  return (
    <div>

      { 

        authStatus ? 
        
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<AppNavigationHeaderComponent />}>
                <Route index element={<HomePage />} />
                <Route path="*" element={<PageNotFoundPage />} />
              </Route>
            </Routes>
          </BrowserRouter>

            :

          <BrowserRouter>
            <AnnouncementBarComponent />
            <Routes>
              <Route path="/" element={<CMSNavigationHeaderComponent />}>
                <Route index element={<NewLandingPage />} />
                <Route path="/how-it-works" element={<HowItWorksPage />}></Route>
                <Route path="/solutions/modeling" element={<ModelingPage />}></Route>
                <Route path="/solutions/simulation" element={<SimulationPage />}></Route>
                <Route path="/solutions/analytics" element={<AnalyticsPage />}></Route>
                <Route path="/solutions/digital-engineering" element={<DigitalEngineeringPage />}></Route>
                <Route path="/platform/KBase" element={<KBasePage />}></Route>
                <Route path="/platform/KDrive" element={<KDrivePage />}></Route>
                <Route path="/platform/Konnect" element={<KonnectPage />}></Route>
                <Route path="/Roadmap" element={<RoadmapPage />}></Route>
                <Route path="/Pricing" element={<PricingPage />}></Route>
                <Route path="*" element={<PageNotFoundPage />} />
              </Route>
            </Routes>
          </BrowserRouter>

      }

    </div>
  );
}

export default App;

//general imports
import styled, { keyframes } from "styled-components";
import VideoOverlay from '../assets/VideoOverlay.png';
import DigitalWorld from '../assets/World.png';
import Mockup from '../assets/Base.png';
import SSOT from '../assets/SSOT.png';
import Predictive from '../assets/Predictive.png';
import Decisions from '../assets/Decisions.png';
import StratInfra from '../assets/StratInfra.png';
import NASA from '../assets/NASA.png';
import DLA from '../assets/DLA.png';
import USAFA from '../assets/USAFA.png';
import NSF from '../assets/NSF.png';
import CarbonGraph from '../assets/Carbon.png';
import HeroIMG from '../assets/HeroBetaIMG2.png';
import VisualizeIMG from '../assets/VisualizeIMG.png';
import UnifyIMG from '../assets/UnifyIMG.png';
import SimulateIMG from '../assets/SimulateIMG.png';

//Key Frames
const SlideInLefttoRight = keyframes`

    0% {
        opacity: 0;
        transform: translateX(-50px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const KuumbaHeroIMG = styled.div`
    background-image: url(${HeroIMG});
    background-repeat: no-repeat;
    background-size: contain;

    width: 100%;
    max-width: 1182px;
    min-width: 375px;
    height: auto;
    aspect-ratio: auto 1182 / 1020;

    margin-left: -45px;

    @media (max-width: 700px) {
        margin-left: 0;
    }

    animation: ${SlideInLefttoRight} 1s ease-in-out;
`;

export const PlatformVisualizeIMG = styled.div`
    background-image: url(${VisualizeIMG});
    background-repeat: no-repeat;
    background-size: contain;

    width: 100%;
    max-width: 740px;
    min-width: 175px;
    height: auto;
    aspect-ratio: auto 740 / 402;
`;

export const PlatformUnifyIMG = styled.div`
    background-image: url(${UnifyIMG});
    background-repeat: no-repeat;
    background-size: contain;

    width: 100%;
    max-width: 740px;
    min-width: 175px;
    height: auto;
    aspect-ratio: auto 740 / 423;
`;

export const PlatformSimulateIMG = styled.div`
    background-image: url(${SimulateIMG});
    background-repeat: no-repeat;
    background-size: contain;

    width: 100%;
    max-width: 740px;
    min-width: 175px;
    height: auto;
    aspect-ratio: auto 740 / 430;
`;

export const CarbonGraphBackground = styled.div`
    background-image: url(${CarbonGraph});
    //background-repeat: no-repeat;
    //width: auto;
    //height: 100%;
    //object-fit: contain;
`;

export const HowItWorksVideoOverlay = styled.div`
    background-image: url(${VideoOverlay});
    background-repeat: no-repeat;
    width: auto;
    height: auto;
`;

export const DigitalWorldImage = styled.img`
    background-image: url(${DigitalWorld});
    background-repeat: no-repeat;
    width: auto;
    height: 100%;
`;

export const KuumbaPrototype = styled.img`
    background-image: url(${Mockup});
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
`;

export const Solutions_SSOT = styled.div`
    background-image: url(${SSOT});
    background-repeat: no-repeat;
    width: 245px;
    height: 245px;
    minHeight: 220px;

    margin-top: -110px;
`;

export const Solutions_Decisions = styled.div`
    background-image: url(${Decisions});
    background-repeat: no-repeat;
    width: 245px;
    height: 245px;
    minHeight: 220px;

    margin-top: -110px;
`;

export const Solutions_Predictive = styled.div`
    background-image: url(${Predictive});
    background-repeat: no-repeat;
    width: 245px;
    height: 245px;
    minHeight: 220px;

    margin-top: -110px;
`;

export const Solutions_StratInfra = styled.div`
    background-image: url(${StratInfra});
    background-repeat: no-repeat;
    width: 245px;
    height: 245px;
    minHeight: 220px;

    margin-top: -110px;
`;

export const Platform_NASA = styled.div`
    background-image: url(${NASA});
    background-repeat: no-repeat;
    width: 315px;
    height: 116px;
`;

export const Platform_DLA = styled.div`
    background-image: url(${DLA});
    background-repeat: no-repeat;
    width: 315px;
    height: 116px;
`;

export const Platform_NSF = styled.div`
    background-image: url(${NSF});
    background-repeat: no-repeat;
    width: 315px;
    height: 116px;
`;

export const Platform_USAFA = styled.div`
    background-image: url(${USAFA});
    background-repeat: no-repeat;
    width: 315px;
    height: 116px;
`;
import React from 'react';

function KDrivePage(props) {
    return (
        <div>
            KDrivePage
        </div>
    );
}

export default KDrivePage;
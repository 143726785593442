import React from 'react';
import { useState } from 'react';
import { AnnouncementBarContainer, NotificationCloseoutBTNContainer } from '../../styles/container.styles';
import { BasicText_White_Small, BasicText_White_Small_Linked } from '../../styles/typography.styles';
import { Grey_X_Decal, White_X_Decal } from '../../styles/decal.styles';
import { useSelector, useDispatch } from 'react-redux';
import { closeBar } from '../../redux/closeAccounementBar.reducer';
import { toggleWaitlist } from '../../redux/modalHandler.reducer';

function AnnouncementBarComponent(props) {

    //////////STATE MANAGEMENT\\\\\\\\\\
    const announcementBarStatus = useSelector((state) => state.closeAnnouncementBar.isAnnouncementBarOpen)
    const waitlistModalStatus = useSelector((state) => state.handleModal.isWaitlistOpen)
    const dispatch = useDispatch()

    const openWaitlist = () => {
        dispatch(toggleWaitlist());
        document.body.style.overflowY = 'hidden';
    }

    const closeWaitlist = () => {
        dispatch(toggleWaitlist());
        document.body.style.overflowY = 'hidden';
    }

    return (
        <AnnouncementBarContainer isOpen={announcementBarStatus}>
            <div />

            <div className='Close Modal Hitbox' style={{ position: 'absolute', cursor: 'pointer', height: "100%", width: "100%", color: 'blue', opacity: '0'}} onClick={() => closeWaitlist()}/>

            <BasicText_White_Small style={{color: '#DE379B'}}>
                Kuumba's pre alpha version 0.0.1. will be released in December, 2023. 
                <BasicText_White_Small_Linked onClick={() => openWaitlist()} style={{marginLeft: '5px'}}>
                    Click here to join the waitlist!
                </BasicText_White_Small_Linked>
            </BasicText_White_Small>

            <NotificationCloseoutBTNContainer onClick={() => dispatch(closeBar())}>
                <Grey_X_Decal />
            </NotificationCloseoutBTNContainer>
            
        </AnnouncementBarContainer>
    );
}

export default AnnouncementBarComponent;
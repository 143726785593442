import React from 'react';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { closeBar } from '../../redux/closeAccounementBar.reducer';
import { toggleWaitlist, toggleHamburgerMenu } from '../../redux/modalHandler.reducer';

//components imports
import JoinWaitlistButton from '../buttons/JoinWaitlist.button';
import PricingButton from '../buttons/Pricing.button';
import LanguageSwitchButton from '../buttons/LanguageSwitch.button';
import HowItWorksVideoModal from '../modals/HowItWorksVideo.modal';
import WaitlistModal from '../modals/Waitlist.modal';

//decal imports
import { Kuumba_Logo_Decal, UDE_Kuumba_Logo_Decal } from '../../styles/decal.styles';

//style imports
import { BlurryModalOverlay, CMSNavContentContainer, CMSNavigationHeaderContainer, FilledButton, UnfilledButton } from '../../styles/container.styles';
import { HeaderLinkText } from '../../styles/typography.styles';
import OpenMenuHamburgerButton from '../buttons/OpenMenuHamburger.button';
import HamburgerMenuModal from '../modals/HamburgerMenu.modal';

function CMSNavigationHeaderComponent(props) {

    //////////STATE MANAGEMENT\\\\\\\\\\
    const announcementBarStatus = useSelector((state) => state.closeAnnouncementBar.isAnnouncementBarOpen)
    const waitlistModalStatus = useSelector((state) => state.handleModal.isWaitlistOpen)
    const hamburgerMenuStatus = useSelector((state) => state.handleModal.isHamburgerMenuOpen)
    const dispatch = useDispatch()

    const [isScrolled, setIsScrolled] = useState(false)
    const [isHowItWorksOpen, setIsHowItWorksOpen] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    //////////STATE FUNCTIONS\\\\\\\\\\
    const isScrolling = () => {
        if(window.scrollY >= 1) {

            setIsScrolled(true)

        } else {
            
            setIsScrolled(false)
            
        }
    };

    const getWindowWidth = () => {

        setWindowWidth(window.innerWidth)

    }

    const navigate = useNavigate();

    const hoverInHowItWorks = () => {
        
        setIsHowItWorksOpen(true)
        
    };

    const hoverOutHowItWorks = () => {
        
        setIsHowItWorksOpen(false)
        
    };

    const openWaitlist = () => {
        
        dispatch(toggleWaitlist());
        document.body.style.overflowY = 'hidden';
        
    }

    window.addEventListener('scroll', isScrolling)
    window.addEventListener('resize', getWindowWidth)

    return (
        <div>
            <CMSNavigationHeaderContainer scrolled={isScrolled} isOpen={announcementBarStatus}>
            
                <div style={{display: 'flex', flexFlow: 'row', marginLeft: '30px', gap: '15px', cursor: 'pointer', alignItems: 'center'}}>

                    <UDE_Kuumba_Logo_Decal style={{marginRight: '15px'}} onClick={() => navigate("/")}/>

                    <HeaderLinkText onMouseEnter={() => hoverInHowItWorks()} onMouseLeave={() => hoverOutHowItWorks()}>
                        How does it work? 
                        {
                            isHowItWorksOpen? 
                            
                            <HowItWorksVideoModal />

                                : 

                            null
                        } 
                    </HeaderLinkText>

                    <HeaderLinkText href='#solutions'>Solutions</HeaderLinkText>

                    <HeaderLinkText href='#platform'>Platform</HeaderLinkText>

                    <HeaderLinkText href='#'>Join Us</HeaderLinkText>

                </div>

                {
                    windowWidth > 600?

                    <div style={{marginRight: '30px', display: 'flex', flexFlow: 'row', alignItems: 'center', gap: '15px'}}>

                        <UnfilledButton href='#pricing'>Pricing</UnfilledButton>

                        <FilledButton href='#'>Get Started</FilledButton>

                        {
                            
                            waitlistModalStatus?
                        
                            <WaitlistModal />

                                :

                            null
                        }
                        <LanguageSwitchButton/>
                    </div>

                        :

                    <div>
                        <OpenMenuHamburgerButton />

                        {
                            
                            waitlistModalStatus?
                        
                            <WaitlistModal />

                                :

                            null
                        }

                    </div>
                }
                
            </CMSNavigationHeaderContainer>
            <Outlet />
            <div>
                {
                    hamburgerMenuStatus?

                    <HamburgerMenuModal />

                        :

                    null
                }
            </div>
        </div>
    );
}

export default CMSNavigationHeaderComponent;
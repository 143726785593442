//general imports
import styled from "styled-components";

//Containers
export const SingleLineForm = styled.form`
    display: flex;
    flex-flow: row;
    gap: 15px;
    width: 100%;

    @media (max-width: 600px) {
        flex-flow: column;
    }
`;

export const AuthFormBox = styled.form`
    display: flex;
    flex-flow: column;
    min-width: 100%;
    gap: 23px;
`;

//Inputs
export const GeneralFormInput = styled.input`
    width: calc(99% - 30px);

    padding: 15px;

    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    color: #969696;
`;

export const AuthInputCell = styled.input`
    background: white;
    border: 0.5px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 3px;
    height: 55px;
    width: 100%;

    padding-left: 25px;
    padding-right: 25px;

    color: #DE379B;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;

    transition: 0.15s linear;

    :focus-within {
        outline: none;
        border-color: #DE379B;
        border-width: 1px;
        transition: 0.15s linear;
    }

    ::placeholder {
        color: #DE379B;
    }
`;

//Buttons
export const FilledFormSubmitButton = styled.input`
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(95.6deg, #F1B314 0%, #DA21B1 100%);
    padding: 15px;
    color: #FFFFFF;
    border-radius: 2px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    transition: 0.15s linear;

    border: 0px;

    &:hover{
        transition: 0.15s linear;
        cursor: pointer;
        filter: brightness(90%);
    }
`;

export const AuthInputCellSubmit = styled.input`
    background: #DE379B;
    border: 0.5px solid #DE379B;
    box-sizing: border-box;
    border-radius: 3px;

    height: 55px;

    padding-left: 25px;
    padding-right: 25px;

    color: white;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;

    transition: 0.25s linear;

    &:hover {
        cursor: pointer;
    }
`;

export const AuthInputRadioButton = styled.input`
    height: 15px;
    width: 15px;
    
    ::label {
        border: 1px solid #EBEBEB;
    }

    &:hover {
        cursor: pointer;
    }
`;
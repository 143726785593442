import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BlurryModalOverlay, DrawerMenuLinks, FilledButton, MenuDrawer, MenuDrawerContent, UnfilledButton } from '../../styles/container.styles';
import { toggleHamburgerMenu, toggleWaitlist } from '../../redux/modalHandler.reducer';
import { Grey_X_Decal } from '../../styles/decal.styles';

function HamburgerMenuModal(props) {

    //////////STATE MANAGEMENT\\\\\\\\\\
    const hamburgerMenuStatus = useSelector((state) => state.handleModal.isHamburgerMenuOpen)
    const waitlistModalStatus = useSelector((state) => state.handleModal.isWaitlistOpen)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    //Functions
    const closeDrawerMenu = () => {

        dispatch(toggleHamburgerMenu());
        document.body.style.overflowY = '';

    }

    const openWaitlist = () => {
        
        dispatch(toggleWaitlist());
        document.body.style.overflowY = 'hidden';
        
    }

    const navigateHome = () => {

        dispatch(toggleHamburgerMenu());
        document.body.style.overflowY = '';
        navigate('/');

    }

    const navigateRoadmap = () => {
        
        dispatch(toggleHamburgerMenu());
        document.body.style.overflowY = '';
        navigate('/roadmap');
        
    }


    return (
        <BlurryModalOverlay>

            <div className='Close Modal Hitbox' style={{cursor: 'pointer', height: "100%", width: "100%", color: 'blue', opacity: '0'}} onClick={() => closeDrawerMenu()}/>

            <MenuDrawer>
                <MenuDrawerContent>

                    <div>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div />
                            <div />
                            <Grey_X_Decal  onClick={() => closeDrawerMenu()}/>
                        </div>

                        <div>
                            <DrawerMenuLinks onClick={() => navigateHome()}>Home</DrawerMenuLinks>

                            <DrawerMenuLinks>How it works?</DrawerMenuLinks>

                            <DrawerMenuLinks onClick={() => navigateRoadmap()}>Roadmap</DrawerMenuLinks>
                        </div>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', gap: '25px'}}>

                        <UnfilledButton style={{fontSize: '16px', height: '25px'}} onClick={() => openWaitlist()}>Pricing</UnfilledButton>

                        <FilledButton style={{fontSize: '16px', height: '25px'}} onClick={() => openWaitlist()}>Get Started</FilledButton>

                    </div>

                </MenuDrawerContent>
            </MenuDrawer>

        </BlurryModalOverlay>
    );
}

export default HamburgerMenuModal;
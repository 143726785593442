import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { AuthFormContainer, AuthFormSwitcherContainer, SocialAuthButton } from '../../styles/container.styles';
import { AuthenticatorAppButtonText, AuthFormHeader, AuthInputRadioButtonText, AuthInputRadioButtonTextAccent, AuthSocialDivider, CreateNewAccountCTA } from '../../styles/typography.styles';
import { AuthFormBox, AuthInputCell, AuthInputCellSubmit, AuthInputRadioButton } from '../../styles/form.styles';
import { GitHubAuthDecal, GoogleAuthDecal, LeftSocialDividerDecal, MicrosoftAuthDecal, QRCAuthDecal, RightSocialDividerDecal } from '../../styles/decal.styles';


//Firebase
import { emailPasswordSignIn, emailPasswordSignUp, gitHubSignUp, googleSignUp, microsoftSignUp, testingPushingData, testingPushingData2 } from '../../config/firebase-auth.utils';
import { auth } from '../../config/firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { setAuth } from '../../redux/auth.slice';


function AuthForm(props) {

    //Redux
    const dispatch = useDispatch()


    //Listen to Auth State Change
    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
            //Dispatch Data
            dispatch(setAuth(JSON.parse(JSON.stringify(currentUser))));
            //Conditional Dispatch
            if (currentUser) {
                console.log("The user is logged in");
            } else {
                console.log("The user is not logged in");
            }
        })
    }, [])

    //User Information
    const [fullName, setFullName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmedPassword, setConfirmedPassword] = useState("");
    const [agreeTOS, setAgreeTOS] = useState(false);
    const [authData, setAuthData] = useState({});

    //Reference Form Inputs
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const confirmedPasswordRef = useRef(null);


    //Handle Auth Data
    const handleAuthFormSubmit = (e) => {

        e.preventDefault();

        //First Handle Creating Full Name
        setFullName(firstName + " " + lastName);

        //Bundle Auth Data
        setAuthData({
            fullName: fullName,
            email: email,
            password: password,
            confirmedPassword: confirmedPassword,
            agreeTOS: agreeTOS,
        });

        //Call Firebase Auth Utils...
        { 
            hasAccount ? 

            emailPasswordSignIn(email, password) 

                : 

            emailPasswordSignUp(email, password, firstName)

        }
        
    };


    //Misc State
    const [hasAccount, setHasAccount] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const getWindowWidth = () => {

        setWindowWidth(window.innerWidth)

    }


    window.addEventListener('resize', getWindowWidth)

    const toggleAccountAuthType = () => {

        setHasAccount(!hasAccount)

    }


    return (

        <AuthFormContainer>

            {
            
                hasAccount ? 

                    <AuthFormHeader>Login to your account</AuthFormHeader>

                        :

                    <AuthFormHeader>Create your account</AuthFormHeader> 
            
            }

            {/* Auth Switcher */}
            <AuthFormSwitcherContainer>
                { 
                
                    hasAccount ? 

                        <div>New to Kuumba?<br /><CreateNewAccountCTA onClick={() => toggleAccountAuthType()}>Create a New Account</CreateNewAccountCTA></div>

                            :

                        <div>Have an Account<br /><CreateNewAccountCTA onClick={() => toggleAccountAuthType()}>Login</CreateNewAccountCTA></div>
                
                }
            </AuthFormSwitcherContainer>

            {/* Auth Form */}
            <AuthFormBox onSubmit={handleAuthFormSubmit}>

                
                {/* Input Names */}
                {
                    hasAccount ? 

                        null

                            :

                        <div style={{display: 'flex', flexFlow: windowWidth < 500 ? 'column' : 'row', gap: '23px'}}>

                            <AuthInputCell onChange={(e) => setFirstName(e.target.value)} type={"text"} placeholder={"First name"} ref={firstNameRef}></AuthInputCell>

                            <AuthInputCell onChange={(e) => setLastName(e.target.value)} type={"text"} placeholder={"Last name"} ref={lastNameRef}></AuthInputCell>

                        </div>

                }

                
                {/* Input Email */}
                <AuthInputCell onChange={(e) => setEmail(e.target.value)} type={"email"} placeholder={"Enter your email address"} ref={emailRef}></AuthInputCell>


                {/* Input Password */}
                <AuthInputCell onChange={(e) => setPassword(e.target.value)} type={"password"} placeholder={"Enter your password"} ref={passwordRef}></AuthInputCell>


                {/* Input Password Confirmation */}
                {
                    hasAccount ? 

                        null 

                            :

                        <AuthInputCell onChange={(e) => setConfirmedPassword(e.target.value)} type={"password"} placeholder={"Confirm your password"} ref={confirmedPasswordRef}></AuthInputCell>

                }


                {/* Agree to Terms of Service */}
                { 
                    hasAccount ? 

                        <div style={{display: 'flex', flexFlow: 'row', gap: '15px', alignItems: 'center'}}>

                            <AuthInputRadioButton onClick={() => setAgreeTOS(!agreeTOS)} type={"checkbox"} />
                            
                            <AuthInputRadioButtonText> Remember me </AuthInputRadioButtonText>
                            
                        </div>

                            :
                        
                        <div style={{display: 'flex', flexFlow: 'row', gap: '15px', alignItems: 'center'}}>

                            <AuthInputRadioButton onClick={() => setAgreeTOS(!agreeTOS)} type={"checkbox"} />
                            
                            <AuthInputRadioButtonText> I agree to Ujima Digital's <AuthInputRadioButtonTextAccent>Terms of Service</AuthInputRadioButtonTextAccent> </AuthInputRadioButtonText>
                            
                        </div>
                }
                

                {/* Input Submission Button */}
                {
                    hasAccount ?

                    <AuthInputCellSubmit type={"submit"} value={"Login"}></AuthInputCellSubmit>

                        :

                    <AuthInputCellSubmit type={"submit"} value={"Create Account"}></AuthInputCellSubmit>

                }


                {/* Forgot Password */}
                {
                    hasAccount ?
                    
                    <CreateNewAccountCTA onClick={() => alert("Be more responsible!")} style={{textAlign: 'center', fontSize: '13px', fontWeight: '700'}}>forgot password</CreateNewAccountCTA>

                        :
                    
                    null

                }


                {/* Divider Decal */}
                <div style={{display: 'flex', flexFlow: 'row', gap: '15px', alignItems: 'center', justifyContent: 'center'}}>

                    <LeftSocialDividerDecal />

                    { 
                        hasAccount ? 

                            <AuthSocialDivider>or sign in with</AuthSocialDivider>
                                
                                :

                            <AuthSocialDivider>or sign up with</AuthSocialDivider>
                    }

                    <RightSocialDividerDecal />

                </div>


                {/* Social Auth */}
                <div style={{display: 'flex', flexFlow: 'row', alignItems: 'center', gap: '23px'}}>

                    <SocialAuthButton onClick={() => googleSignUp()}>

                        <GoogleAuthDecal />

                    </SocialAuthButton>

                    <SocialAuthButton onClick={() => gitHubSignUp()}>

                        <GitHubAuthDecal />

                    </SocialAuthButton>

                    <SocialAuthButton onClick={() => microsoftSignUp()}>

                        <MicrosoftAuthDecal />
                        
                    </SocialAuthButton>

                </div>

                {/* Authenticator App Registration */}
                {
                    hasAccount ? 
                    
                    <SocialAuthButton style={{gap: '15px'}}>

                        <AuthenticatorAppButtonText>{ windowWidth > 500 ? 'Sign in using authenticator app' : 'Authenticator App' }</AuthenticatorAppButtonText>

                        <QRCAuthDecal />

                    </SocialAuthButton>

                        :

                    null

                }
                
                
            </AuthFormBox>

        </AuthFormContainer>

    );
}

export default AuthForm;
import React from 'react';

function RoadmapPage(props) {
    return (
        <div>
            Roadmap
        </div>
    );
}

export default RoadmapPage;
import { createSlice } from "@reduxjs/toolkit";

const initialState = {

    isWaitlistOpen: false,
    isHamburgerMenuOpen: false,

}

export const toggleModals = createSlice({
    name: "modalHandler",
    initialState,
    reducers: {

        toggleWaitlist: (state) => ({
            isWaitlistOpen: !state.isWaitlistOpen
        }),

        toggleHamburgerMenu: (state) => ({
            isHamburgerMenuOpen: !state.isHamburgerMenuOpen
        }),
    }
});

export const { toggleWaitlist, toggleHamburgerMenu } = toggleModals.actions

export default toggleModals.reducer
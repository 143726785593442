import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { toggleWaitlist } from '../../redux/modalHandler.reducer';

//Styles
import { BlurryModalOverlay, WaitlistModalContainer } from '../../styles/container.styles';
import { Grey_X_Decal } from '../../styles/decal.styles';
import { Kuumba_Text_Accent, Medium_Montser_Black } from '../../styles/typography.styles';

//Components
import SingleBarEmailForm from '../forms/SingleBarEmail.form';

function WaitlistModal(props) {

    //////////STATE MANAGEMENT\\\\\\\\\\
    const waitlistModalStatus = useSelector((state) => state.handleModal.isWaitlistOpen)
    const dispatch = useDispatch()

    const closeWaitlist = () => {
        
        dispatch(toggleWaitlist());
        document.body.style.overflowY = '';
        
    }

    return (
        <BlurryModalOverlay>
            
            <WaitlistModalContainer style={{position: 'relative'}}>

                <Grey_X_Decal onClick={() => closeWaitlist()} style={{position: 'absolute', top: '0', right: '0'}} />

                <Medium_Montser_Black>
                    Thank you for your interest in <Kuumba_Text_Accent>Kuumba (Koo-OOM-bah)</Kuumba_Text_Accent>. 
                    We are building a platform that helps make it easy for creatives, engineers, scientist, 
                    and innovators build immersive digital worlds within the browser. We are still in devlopment 
                    so please enter your email to recieve updates of our pre alpha release :)
                </Medium_Montser_Black>

                <SingleBarEmailForm />

            </WaitlistModalContainer>
        </BlurryModalOverlay>
    );
}

export default WaitlistModal;
import React from 'react';
import { useEffect, useState } from 'react';
import { closeBar } from '../redux/closeAccounementBar.reducer';
import { useSelector, useDispatch } from 'react-redux';

//Styles
import { AuthContainer, FAQsGridContainer, PageBodyContainer, PlatformGridContainer, PriciingTablePink, PriciingTableWhite, PricingBestForSmallTeams, PricingButtonPink, PricingButtonWhite, PricingGridContainer, SolutionsGridContainer } from '../styles/container.styles';
import { DotDecals, EnterpriseDecal, IndieDecal, PricingCheckMarkDecal, ScheduleFacesDecal, Social_Icon_Dribble_Decal, Social_Icon_LinkedIn_Decal, Social_Icon_Twitter_Decal, Social_Icon_YouTube_Decal, Swizzle, TeamDecal, TextFlareDecal, UDE_Footer_Logo_Decal } from '../styles/decal.styles';
import { CMS_Grey_Paragraph_Body_Text, CMS_Large_Body_Text, CMS_Paragraph_Body_Text, CMS_Small_Body_Text, FAQsText, Footer_Copywrite_Text, PinkTextHighlight, PlatformNumberTextDecal, PriceText, PricingHeaderText, PricingParagraphText, PricingSubContentText, PricingSubHeaderText, SmallPricingDetailText, ToSandPrivacyText } from '../styles/typography.styles';
import { CarbonGraphBackground, KuumbaHeroIMG, PlatformSimulateIMG, PlatformUnifyIMG, PlatformVisualizeIMG, Platform_DLA, Platform_NASA, Platform_NSF, Platform_USAFA, Solutions_Decisions, Solutions_Predictive, Solutions_SSOT, Solutions_StratInfra } from '../styles/image.styles';
import AuthForm from '../components/forms/Auth.form';


function NewLandingPage(props) {

    //////////STATE MANAGEMENT\\\\\\\\\\
    const announcementBarStatus = useSelector((state) => state.closeAnnouncementBar.isAnnouncementBarOpen)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const dispatch = useDispatch()

    useEffect(() => {
        document.body.style.overflowX = 'hidden';
        document.title = 'Kuumba - Digital Twins, Intelligent Infrastructure, IoT, and Smart Buildings';
      });

    const getWindowWidth = () => {

        setWindowWidth(window.innerWidth)

    }

    window.addEventListener('resize', getWindowWidth)

    return (
        <PageBodyContainer id='get-started' style={{background: 'white'}} isOpen={announcementBarStatus}>

            <div style={{width: '100%', display: 'flex', flexFlow: 'column', alignItems: 'center', background: 'white'}}>

                {/************* HERO SECTION *************/}
                <div id='getting-started' style={{display: 'flex', flexFlow: 'row wrap', alignItems: windowWidth < 950 ? 'center' : '', justifyContent: 'space-between', width: '90%', maxWidth: '1440px', rowGap: ''}}>

                    <KuumbaHeroIMG />

                    <AuthContainer>

                        <AuthForm />

                        <ToSandPrivacyText style={{marginTop: '23px', textAlign: 'center'}}>

                            <span style={{textDecorationLine: 'underline', marginRight: '5px'}}>Terms of Service </span>

                            •
                            
                            <span style={{textDecorationLine: 'underline', marginLeft: '5px'}}> Privacy Policy</span>

                        </ToSandPrivacyText>

                    </AuthContainer>

                </div>


                {/************* SOLUTIONS SECTION *************/}
                <div id='solutions' style={{display: 'flex', flexFlow: 'column', marginTop: windowWidth > 950 ? '-150px' : '0px', paddingTop: '80px', paddingBottom: '80px', gap: '220px', width: '90%', maxWidth: '1440px'}}>


                    <div style={{display: 'flex', flexFlow: 'column', alignItems: 'center', gap: '15px', justifyContent: 'center'}}>

                        { windowWidth > 500 ? <DotDecals style={{marginBottom: '-285px', marginRight: '-1000px'}}/> : null }

                        <CMS_Large_Body_Text style={{textAlign: 'center', maxWidth: '854px'}}>Build digital twins to inform decisions around your portfolio</CMS_Large_Body_Text>

                        <CMS_Paragraph_Body_Text style={{maxWidth: '936px'}}>Creating digital twins enhances your ability to develop, manage and sustain your buildings, infrastructure, and assets.</CMS_Paragraph_Body_Text>

                    </div>

                    <SolutionsGridContainer>

                        <div style={{
                            display: 'flex',
                            flexFlow: 'column',
                            background: '#F6F6F6',
                            maxHeight: '230px',
                            alignItems: 'center', justifyContent: 'center',
                            gap: '30px', 
                            paddingBottom: '40px', 
                            borderRadius: '2px'
                        }}>

                            <Solutions_SSOT />

                            <CMS_Small_Body_Text style={{maxWidth: '90%', textAlign: 'center'}}>Build & visualize single a source of truth</CMS_Small_Body_Text>

                        </div>

                        <div style={{
                            display: 'flex',
                            flexFlow: 'column',
                            background: '#F6F6F6',
                            maxHeight: '230px',
                            alignItems: 'center', justifyContent: 'center',
                            gap: '30px', 
                            paddingBottom: '40px', 
                            borderRadius: '2px'
                        }}>

                            <Solutions_Decisions />

                            <CMS_Small_Body_Text style={{maxWidth: '90%', textAlign: 'center'}}>Data modeling & decision support</CMS_Small_Body_Text>

                        </div>

                        <div style={{
                            display: 'flex',
                            flexFlow: 'column',
                            background: '#F6F6F6',
                            maxHeight: '230px',
                            alignItems: 'center', justifyContent: 'center',
                            gap: '30px', 
                            paddingBottom: '40px', 
                            borderRadius: '2px'
                        }}>

                            <Solutions_Predictive />

                            <CMS_Small_Body_Text style={{maxWidth: '90%', textAlign: 'center'}}>Predictive maintenance modeling</CMS_Small_Body_Text>

                        </div>

                        <div style={{
                            display: 'flex',
                            flexFlow: 'column',
                            background: '#F6F6F6',
                            maxHeight: '230px',
                            alignItems: 'center', justifyContent: 'center',
                            gap: '30px', 
                            paddingBottom: '40px', 
                            borderRadius: '2px'
                        }}>

                            <Solutions_StratInfra />

                            <CMS_Small_Body_Text style={{maxWidth: '90%', textAlign: 'center'}}>Strategic infrastructure planning</CMS_Small_Body_Text>

                        </div>

                    </SolutionsGridContainer>

                </div>


                {/************* PLATFORM SECTION *************/}
                <div id='platform' style={{display: 'flex', flexFlow: 'column', paddingTop: '50px', paddingBottom: '200px', gap: windowWidth > 650 ? '165px' : '65px', background: 'white', width: '90%', maxWidth: '1440px'}}>

                    <div style={{display: 'flex', flexFlow: windowWidth > 1080 ? 'row' : 'column', alignItems: 'center', justifyContent: 'space-between', rowGap: '75px'}}>

                        <div style={{display: 'flex', flexFlow: 'column', gap: '20px', maxWidth: '700px'}}>

                            <PlatformNumberTextDecal style={{marginBottom: '-150px', textAlign: 'right'}}>01</PlatformNumberTextDecal>

                            <CMS_Large_Body_Text>Simulate the real world in the digital one.</CMS_Large_Body_Text>

                            <CMS_Paragraph_Body_Text style={{textAlign: 'left'}}>Explore the physical world through a data focused lens within the digital one. Digitalizing your portfolio will allow you simulate various scenarous and draw conclusions that can help reduce costs.</CMS_Paragraph_Body_Text>

                        </div>

                        <div style={{width: '100%', marginBottom: windowWidth > 1080 ? '-65px': '0px'}}>

                            <PlatformSimulateIMG />

                        </div>

                    </div>

                    <div style={{display: 'flex', flexFlow: windowWidth > 1080 ? 'row' : 'column-reverse', alignItems: 'center', justifyContent: 'space-between', rowGap: '75px'}}>

                        <div style={{width: '100%', marginBottom: windowWidth > 1080 ? '-65px': '0px'}}>

                            <PlatformUnifyIMG />

                        </div>

                        <div style={{display: 'flex', flexFlow: 'column', gap: '20px', maxWidth: '700px'}}>

                            <PlatformNumberTextDecal style={{marginBottom: '-150px', textAlign: 'right'}}>02</PlatformNumberTextDecal>

                            <CMS_Large_Body_Text>Unify and model your real world data.</CMS_Large_Body_Text>

                            <CMS_Paragraph_Body_Text style={{textAlign: 'left'}}>Eliminate data silos across your entire enterprise and transform your data into action and insight. Unifying and modeling your data will allow you to be able to gain critical insight across your asset portfolio.</CMS_Paragraph_Body_Text>

                        </div>

                    </div>

                    <div style={{display: 'flex', flexFlow: windowWidth > 1080 ? 'row' : 'column', alignItems: 'center', justifyContent: 'space-between', rowGap: '75px'}}>

                        <div style={{display: 'flex', flexFlow: 'column', gap: '20px', maxWidth: '700px'}}>

                            <PlatformNumberTextDecal style={{marginBottom: '-150px', textAlign: 'right'}}>03</PlatformNumberTextDecal>

                            <CMS_Large_Body_Text>Visualize & utilize dormant data within your portfolio.</CMS_Large_Body_Text>

                            <CMS_Paragraph_Body_Text style={{textAlign: 'left'}}>Employ previously unused data that lives within your infrastructure information systems. Our system provides highly customizable data visualization and modeling tools to help you gain more visibility.</CMS_Paragraph_Body_Text>

                        </div>

                        <div style={{width: '100%', marginBottom: windowWidth > 1080 ? '-65px': '0px'}}>

                            <PlatformVisualizeIMG />

                        </div>

                    </div>
                    
                </div>


                {/************* PRICING SECTION *************/}
                <CarbonGraphBackground id='pricing' style={{display: 'flex', flexFlow: 'column', alignItems: 'center', width: windowWidth, paddingTop: '75px', paddingBottom: '75px'}}>

                    <div style={{display: 'flex', flexFlow: 'column', width: '87%', maxWidth: '1440px'}}>

                        <div style={{display: 'flex', flexFlow: 'column', alignSelf: 'center', gap: '17px', maxWidth: '850px'}}>

                            <PricingHeaderText style={{textAlign: 'center'}}><span><Swizzle style={{marginBottom: '-75px', marginTop: '45px', marginLeft: '45px', marginRight: '-45px'}} /> Simple</span> pricing, for everyone.</PricingHeaderText>
                            
                            <PricingParagraphText style={{textAlign: 'center'}}>Our solution scales to the size and demand that you need for your projects</PricingParagraphText>

                        </div>

                        <PricingGridContainer>

                            <PriciingTableWhite>

                                <div style={{display: 'flex', flexFlow: 'column', gap: '18px'}}>

                                    <IndieDecal />

                                    <PricingSubHeaderText>Individual</PricingSubHeaderText>

                                    <PricingSubContentText>This is the perfect solution for those who are looking to contribute to open projects on our platform</PricingSubContentText>

                                    <PriceText>$0<span style={{fontSize: '13px', lineHeight: '15px'}}>/ for 1 editor & server</span></PriceText>

                                    <PricingButtonWhite href='#' style={{display: 'flex', flexFlow: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: '10px'}}>Signup for free</PricingButtonWhite>

                                    <div style={{display: 'flex', flexFlow: 'column', gap: '26px'}}>

                                        <div style={{display: 'flex', flexFlow: 'row', gap: '10px', alignItems: 'center', }}>

                                            <PricingCheckMarkDecal />

                                            <SmallPricingDetailText>project size up to 1 Gb</SmallPricingDetailText>

                                        </div>

                                        <div style={{display: 'flex', flexFlow: 'row', gap: '10px', alignItems: 'center', }}>

                                            <PricingCheckMarkDecal />

                                            <SmallPricingDetailText>IFC File Viewer</SmallPricingDetailText>

                                        </div>

                                        <div style={{display: 'flex', flexFlow: 'row', gap: '10px', alignItems: 'center', }}>

                                            <PricingCheckMarkDecal />

                                            <SmallPricingDetailText>30 mins of Cloud XDN streaming</SmallPricingDetailText>

                                        </div>

                                    </div>

                                </div>

                            </PriciingTableWhite>

                            <div>
                                <PricingBestForSmallTeams>Best for small teams</PricingBestForSmallTeams>

                                <PriciingTablePink>

                                    <div style={{display: 'flex', flexFlow: 'column', gap: '18px'}}>

                                        <TeamDecal />

                                        <PricingSubHeaderText>Team</PricingSubHeaderText>

                                        <PricingSubContentText>This pricing tier is best for those who are working on small engineering CAD, BIM, and data projects</PricingSubContentText>

                                        <PriceText>$45<span style={{fontSize: '13px', lineHeight: '15px'}}>/ editor / server</span></PriceText>

                                        <PricingButtonPink href='#' style={{display: 'flex', flexFlow: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: '10px'}}>Start 14 day free trial</PricingButtonPink>

                                        <div style={{display: 'flex', flexFlow: 'column', gap: '26px'}}>

                                            <div style={{display: 'flex', flexFlow: 'row', gap: '10px', alignItems: 'center', }}>

                                                <PricingCheckMarkDecal />

                                                <SmallPricingDetailText>500 Gb of storage space</SmallPricingDetailText>

                                            </div>

                                            <div style={{display: 'flex', flexFlow: 'row', gap: '10px', alignItems: 'center', }}>

                                                <PricingCheckMarkDecal />

                                                <SmallPricingDetailText>Data Modeling Tool Suite</SmallPricingDetailText>

                                            </div>

                                            <div style={{display: 'flex', flexFlow: 'row', gap: '10px', alignItems: 'center', }}>

                                                <PricingCheckMarkDecal />

                                                <SmallPricingDetailText>Unlimted Cloud XDN Streaming</SmallPricingDetailText>

                                            </div>

                                        </div>

                                    </div>

                                </PriciingTablePink>

                            </div>
                            

                            <PriciingTableWhite>

                                <div style={{display: 'flex', flexFlow: 'column', gap: '18px'}}>

                                    <EnterpriseDecal />

                                    <PricingSubHeaderText>Enterprise</PricingSubHeaderText>

                                    <PricingSubContentText>This robust tier is all about custom data operations, digital twin, and digital engieering services</PricingSubContentText>

                                    <PriceText>Talk to us</PriceText>

                                    <PricingButtonWhite href={'https://calendly.com/ujima/15min'} target="_blank" style={{display: 'flex', flexFlow: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: '10px', gap: '11px'}}>

                                        <div>Schedule a conversation</div>

                                        <ScheduleFacesDecal />

                                    </PricingButtonWhite>

                                    <div style={{display: 'flex', flexFlow: 'column', gap: '26px'}}>

                                        <div style={{display: 'flex', flexFlow: 'row', gap: '10px', alignItems: 'center', }}>

                                            <PricingCheckMarkDecal />

                                            <SmallPricingDetailText>Custom BIM Development Services</SmallPricingDetailText>

                                        </div>

                                        <div style={{display: 'flex', flexFlow: 'row', gap: '10px', alignItems: 'center', }}>

                                            <PricingCheckMarkDecal />

                                            <SmallPricingDetailText>Large Scale Infrastructure Projects</SmallPricingDetailText>

                                        </div>

                                        <div style={{display: 'flex', flexFlow: 'row', gap: '10px', alignItems: 'center', }}>

                                            <PricingCheckMarkDecal />

                                            <SmallPricingDetailText>Advanced MS&A</SmallPricingDetailText>

                                        </div>

                                    </div>

                                </div>

                            </PriciingTableWhite>

                        </PricingGridContainer>

                    </div>

                </CarbonGraphBackground>


                {/************* SPONSORS SECTION *************/}
                <div id='sponsors' style={{display: 'flex', flexFlow: 'column', paddingTop: '65px', paddingBottom: '65px', background: 'white', gap: '65px', width: '90%', maxWidth: '1440px'}}>

                    <CMS_Grey_Paragraph_Body_Text style={{display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'center'}}>

                        Sponsored by some of the most innovative federal agencies

                    </CMS_Grey_Paragraph_Body_Text>

                    <PlatformGridContainer>

                        <Platform_NASA />

                        <Platform_DLA />

                        <Platform_NSF />

                        <Platform_USAFA />

                    </PlatformGridContainer>

                </div>


                {/************* FAQs SECTION *************/}
                <div id='faqs' style={{paddingTop: '50px', paddingBottom: '50px', background: 'white', width: '90%', maxWidth: '1440px'}}>

                    <div style={{display: 'flex', flexFlow: 'column', gap: '15px'}}>

                        <div style={{display: 'flex'}}>
                            
                            <CMS_Large_Body_Text style={{color: '#1D1D1D'}}>Frequently Asked <PinkTextHighlight>Questions</PinkTextHighlight></CMS_Large_Body_Text>

                            { windowWidth > 750 ? 

                                <TextFlareDecal style={{marginTop: '-20px'}}/>

                                    : 

                                null

                            }

                        </div>

                        <CMS_Paragraph_Body_Text style={{maxWidth: '950px', textAlign: 'left', color: '#1D1D1D', fontSize: '22px'}}>
                            
                            If you can’t find what you’re looking for, send an email to our support team at <PinkTextHighlight style={{fontWeight: '600'}}>jambo@ujimadigital.com</PinkTextHighlight> and someone will get back to you.
                            
                        </CMS_Paragraph_Body_Text>

                    </div>

                    <FAQsGridContainer style={{ marginBottom: '65px', alignItems: 'flex-start' }}>

                        <div style={{display: 'flex', flexFlow: 'column', gap: '5px'}}>

                            <FAQsText style={{fontWeight: '600'}}>What are digital "data" twins?</FAQsText>

                            <FAQsText>They are virtual representations of physical assets, such as planes, satellites, buildings, people, processes, etc. They are the culmination of all models, data, and processes belonging to a system.</FAQsText>

                        </div>

                        <div style={{display: 'flex', flexFlow: 'column', gap: '5px'}}>

                            <FAQsText style={{fontWeight: '600'}}>What is a digital twin used for?</FAQsText>

                            <FAQsText>They exist to streamline the product lifecycle management of a particular thing and present a medium where data can be utilized to make decisions around, cost, performance, and optimization of that asset.</FAQsText>

                        </div>

                        <div style={{display: 'flex', flexFlow: 'column', gap: '5px'}}>

                            <FAQsText style={{fontWeight: '600'}}>How does a digital twin work?</FAQsText>

                            <FAQsText>A digital twin works by making digital representations of the multi dimensionality of a physical object. They model geometry, behaviors, sensory data, and physics to be able to create a high fidelity replica of a real world thing, online.</FAQsText>

                        </div>

                        <div style={{display: 'flex', flexFlow: 'column', gap: '5px'}}>

                            <FAQsText style={{fontWeight: '600'}}>Where is my data stored?</FAQsText>

                            <FAQsText>Your data will be stored either on your own servers if you choose to deploy an on premise solution, or it will be stored in the cloud. We utilize a mutlicloud approach and allow you to store your data on either AWS, Azue, or Google Cloud.</FAQsText>

                        </div>

                        <div style={{display: 'flex', flexFlow: 'column', gap: '5px'}}>

                            <FAQsText style={{fontWeight: '600'}}>Are digital twins secure?</FAQsText>

                            <FAQsText>Security is one of the highest measures of suceess we hold ourselves to, and we provide tools such as multi-factor authentication to ensure that only people who have access to your accounts can use them.</FAQsText>

                        </div>

                    </FAQsGridContainer>

                </div>

               
            </div>


            {/************* FOOTER SECTION *************/}
            <div style={{
                paddingLeft: '30px', paddingRight: '30px',
                paddingTop: '35px', paddingBottom: '35px',
                width: 'calc(100% - 60px)', 
                background: 'white',
                display: 'grid', justifyContent: 'stretch',
                gridTemplateColumns: 'repeat(3, minmax(200px, 1fr))', 
            }}>

                <Footer_Copywrite_Text>Copyright © 2023 Ujima Digital Enterprises.</Footer_Copywrite_Text>

                { windowWidth > 500 ? <UDE_Footer_Logo_Decal style={{justifySelf: 'center',}}/> : null }

                {
                    windowWidth > 500 ? 
                    
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifySelf: 'flex-end',
                            justifyContent: 'space-between',
                            gap: '45px', maxWidth: '231px',
                        }}>
        
                            <Social_Icon_Dribble_Decal />
        
                            <Social_Icon_Twitter_Decal />
        
                            <Social_Icon_YouTube_Decal />
                            
                            <Social_Icon_LinkedIn_Decal />
        
                        </div>

                            : 

                        null
                }

                

            </div>
            
        </PageBodyContainer>
    );
}

export default NewLandingPage;
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isAnnouncementBarOpen: false,
}

export const toggleAnnouncementBar = createSlice({
    name: "closeAnnouncementBar",
    initialState,
    reducers: {
        closeBar: (state) => ({
            isAnnouncementBarOpen: !state.isAnnouncementBarOpen
        }),
    }
});

export const { closeBar } = toggleAnnouncementBar.actions

export default toggleAnnouncementBar.reducer
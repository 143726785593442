import React from 'react';

function PageNotFoundPage(props) {
    return (
        <div>
            404 Page Not Nound
        </div>
    );
}

export default PageNotFoundPage;
import React from 'react';
import { CMSNavigationHeaderContainer } from '../../styles/container.styles';
import { useEffect, useState } from 'react';
import { Purple_Kuumba_Logo_Decal } from '../../styles/decal.styles';
import { Outlet } from 'react-router-dom';

function AppNavigationHeaderComponent(props) {

    const [isScrolled, setIsScrolled] = useState(true)
    const [isHowItWorksOpen, setIsHowItWorksOpen] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    //////////STATE FUNCTIONS\\\\\\\\\\
    const isScrolling = () => {
        if(window.scrollY >= 0) {

            setIsScrolled(true)

        } else {
            
            setIsScrolled(false)
            
        }
    };

    const getWindowWidth = () => {

        setWindowWidth(window.innerWidth)

    }

    const hoverInHowItWorks = () => {
        
        setIsHowItWorksOpen(true)
        
    };

    window.addEventListener('scroll', isScrolling)
    window.addEventListener('resize', getWindowWidth)

    return (
        <div>
            <CMSNavigationHeaderContainer scrolled={isScrolled} isOpen={false}>

                <div style={{display: 'flex', flexFlow: 'row', marginLeft: '30px', gap: '15px', cursor: 'pointer'}}>

                    <Purple_Kuumba_Logo_Decal style={{marginRight: '10px'}} onClick={() => alert("I can't take you home chief...")}/>
                    
                </div>

            </CMSNavigationHeaderContainer>

            <Outlet />
        </div>
    );
}

export default AppNavigationHeaderComponent;
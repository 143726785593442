import React from 'react';

function PricingPage(props) {
    return (
        <div>
            Pricing
        </div>
    );
}

export default PricingPage;